import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import axios from './plugins/axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Multiselect from 'vue-multiselect'

// Add the required rule
extend('required', {
  ...required,
  message: 'Field / inputan berikut wajib diisi'
});

Vue.config.productionTip = false
Vue.use(axios)
Vue.use(VueSweetalert2);

// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('MultiSelect', Multiselect)

// new Vue({
// 	router,
// 	store,
// 	render: h => h(App)
// }).$mount('#app')

"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store';
import router from '../router';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true
require('promise.prototype.finally').shim()

let config = {
  // baseURL: `http://dhr-api.test` || process.env.baseURL || process.env.apiUrl || ""
  baseURL: process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_GATEWAY_API
  // || `https://api.dhr.indomaretgroup.com/` || `http://dhr-api.test` 
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    router.push({
      path : `/`
    })
    //if (store.getters.token)
      //config.headers['Authorization'] = 'Bearer' + store.getters.token
    //config.withCredentials = true;

    return config;
  },
  function(error) {
    // console.log("error request")
    // if (error.response.status === 401) {
    //   store.dispatch('logout')
    //   console.log("masuk request")
    //   router.push({
    //     path : `/`
    //   })
    // }
    router.push({
      path : `/`
    })

    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    router.push({
      path : `/`
    })
    return response;
  },
  function(error) {
    // console.log("error response")
    // if (error.response.status === 401) {
    //   store.dispatch('logout')
    //   console.log("masuk response")
    //   router.push({
    //     path : `/`
    //   })
    // }

    router.push({
      path : `/`
    })
      

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;

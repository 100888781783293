import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userLogin: null,
		access: null,
		storedPKBerjalan: null,
		storedPKTerhutang: null,
		storedPersetujuanPK: null,
		storedGantiPejabat: null,
		storedUbahPK: null,
		storedPersetujuanGaransi: null,
		//token: null
	},
	mutations: {
		SET_USERLOGIN(state, userLogin) {
			state.userLogin = userLogin
		},

		SET_ACCESS(state, access) {
			state.access = access
		},

		SET_STOREDPKBERJALAN(state, storedPKBerjalan) {
			state.storedPKBerjalan = storedPKBerjalan
		},

		SET_STOREDPKTERHUTANG(state, storedPKTerhutang) {
			state.storedPKTerhutang = storedPKTerhutang
		},

		SET_STOREDPERSETUJUANPK(state, storedPersetujuanPK) {
			state.storedPersetujuanPK = storedPersetujuanPK
		},

		SET_STOREDGANTIPEJABAT(state, storedGantiPejabat) {
			state.storedGantiPejabat = storedGantiPejabat
		},

		SET_STOREDUBAHPK(state, storedUbahPK) {
			state.storedUbahPK = storedUbahPK
		},

		SET_STOREDPERSETUJUANGARANSI(state, storedPersetujuanGaransi) {
			state.storedPersetujuanGaransi = storedPersetujuanGaransi
		},
		// SET_TOKEN(state, token) {
		// 	state.token = token
		// },
	},
	actions: {
		async isAuthenticated (context) {
			return await new Promise ((resolve,reject) => {
				let postData = {
					code: 1
				}
				axios.post(
					process.env.VUE_APP_GATEWAY_API + 'auth', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
				  	})
					.then( response => {
						context.commit('SET_USERLOGIN', response.data.result)
						localStorage.user = response.data.result.nik
						//context.commit('SET_TOKEN', response.data.result.token)
						resolve(response.data.result)
					})
					.catch( e => {
						if (e.response.status == 401) {
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},

		async checkRole (context) {
			return await new Promise ((resolve,reject) => {
				let postData = {
					code: 9
				}
				axios.post(
					process.env.VUE_APP_GATEWAY_API + 'master/users', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
				  	})
					.then( response => {
						//console.log(response.data.result)
						context.commit('SET_ACCESS', response.data.result)
						//context.commit('SET_TOKEN', response.data.result.token)
						resolve(response.data.result)
					})
					.catch( e => {
						console.log(e)
						if (e.response.status == 401) {
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},

		// setToken (context, payload) {
		// 	context.commit('SET_TOKEN', payload)
		// },

		setUserLogin (context, payload) {
			context.commit('SET_USERLOGIN', payload)
			localStorage.user = payload.nik
		},

		setAccess (context, payload) {
			context.commit('SET_ACCESS', payload)
		},

		setStoredPKBerjalan (context, payload) {
			context.commit('SET_STOREDPKBERJALAN', payload)
		},

		setStoredPKTerhutang (context, payload) {
			context.commit('SET_STOREDPKTERHUTANG', payload)
		},

		setStoredPersetujuanPK (context, payload) {
			context.commit('SET_STOREDPERSETUJUANPK', payload)
		},

		setStoredGantiPejabat (context, payload) {
			context.commit('SET_STOREDGANTIPEJABAT', payload)
		},

		setStoredUbahPK (context, payload) {
			context.commit('SET_STOREDUBAHPK', payload)
		},

		setStoredPersetujuanGaransi (context, payload) {
			context.commit('SET_STOREDPERSETUJUANGARANSI', payload)
		},

		async logout (context) {
			const postData = {
				Data: {
					nik: this.getters.isUserLogin.nik
				}
			}
			//console.log(JSON.stringify(postData))
			return await new Promise ((resolve,reject) => {
				axios.post(
					process.env.VUE_APP_GATEWAY_ESS + 'Logout', postData, 
					{
						withCredentials: true, //harus ditambahkan jika backend cookies
					})
					.then( response => {
						context.commit('SET_USERLOGIN', null)
						context.commit('SET_STOREDPKBERJALAN', null)
						context.commit('SET_STOREDPKTERHUTANG', null)
						context.commit('SET_STOREDPERSETUJUANPK', null)
						context.commit('SET_STOREDGANTIPEJABAT', null)
						context.commit('SET_STOREDUBAHPK', null)
						context.commit('SET_STOREDPERSETUJUANGARANSI', null)
						localStorage.removeItem('user')
						resolve(response.data.result)
					})
					.catch( e => {
						console.log(e)
						if (e.response.status == 401) {
							context.commit('SET_USERLOGIN', null)
							localStorage.removeItem('user')
							router.push({name: 'login'})
						}
						reject(e)
					})
			})
		},
	},

	getters: {
		//isAuthenticated: state => !!state.token,
		isUserLogin: state => state.userLogin,
		access: state => state.access,
		storedPKBerjalan: state => state.storedPKBerjalan,
		storedPKTerhutang: state => state.storedPKTerhutang,
		storedPersetujuanPK: state => state.storedPersetujuanPK,
		storedGantiPejabat: state => state.storedGantiPejabat,
		storedUbahPK: state => state.storedUbahPK,
		storedPersetujuanGaransi: state => state.storedPersetujuanGaransi,
	},

	modules: {
	}
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import Login from '../views/Login.vue'
// import Dashboard from '../views/Dashboard.vue'
// import Beranda from '../views/Beranda.vue'
// import PengajuanPK from '../views/PengajuanPK.vue'
// //import PKReferensi from '../views/PengajuanPK/PKReferensi.vue'
// import PersetujuanPK from '../views/PersetujuanPK/PersetujuanPK.vue'
// import PersetujuanGantiPejabat from '../views/PersetujuanPK/PersetujuanGantiPejabat.vue'
// import PersetujuanUbahPK from '../views/PersetujuanPK/PersetujuanUbahPK.vue'
// import PersetujuanGaransi from '../views/PersetujuanPK/PersetujuanGaransi.vue'
// import PKBerjalan from '../views/PemantauanPK/PKBerjalan.vue'
// import PKTerhutang from '../views/PemantauanPK/PKTerhutang.vue'
// import RiwayatPK from '../views/RiwayatPK.vue'
// import MasterPejabatApproval from '../views/Master/MasterPejabatApproval.vue'
// import FormPejabatApproval from '../views/Master/FormPejabatApproval.vue'
// import MasterLeadTime from '../views/Master/MasterLeadTime.vue'
// import FormLeadTime from '../views/Master/FormLeadTime.vue'
// import MasterDepartemen from '../views/Master/MasterDepartemen.vue'
// import FormDepartemen from '../views/Master/FormDepartemen.vue'
// import MasterJabatan from '../views/Master/MasterJabatan.vue'
// import FormJabatan from '../views/Master/FormJabatan.vue'
// import MasterUser from '../views/Master/MasterUser.vue'
// import FormUser from '../views/Master/FormUser.vue'
// import BudgetPK from '../views/Master/BudgetPK.vue'
// import FormBudgetPK from '../views/Master/FormBudgetPK.vue'
// import Report from '../views/Report.vue'
// // import LPKB from '../views/Report/LPKB.vue'
// // import LSLP from '../views/Report/LSLP.vue'
// import DetailPK from '../views/DetailPK.vue'
// import DetailRekrutmen from '../views/DetailRekrutmen.vue'
// import store from '@/store'


Vue.use(VueRouter)
Vue.use(Buefy)

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	if (to.name != 'login') {
		next({name: "login"})
	} else {
		next()
	}
	
	// //const isAuth = store.getters.isAuthenticated
	// //const isUserLogin = store.getters.isUserLogin
	// const storedPKBerjalan = store.getters.storedPKBerjalan
	// const storedPKTerhutang = store.getters.storedPKTerhutang
	// const storedPersetujuanPK = store.getters.storedPersetujuanPK
	// const storedGantiPejabat = store.getters.storedGantiPejabat
	// const storedUbahPK = store.getters.storedUbahPK
	// const storedPersetujuanGaransi = store.getters.storedPersetujuanGaransi

	// if (!localStorage.user) {
	// 	if (to.name == 'login') {
	// 		next()
	// 	} else {
	// 		localStorage.routingTo = to.name
	// 		next({name: "login"})
	// 	}
	// } else {
	// 	if (store.dispatch('isAuthenticated')) {
	// 		store.dispatch('checkRole').then(response => {
	// 			//console.log(response)
	// 			if (to.name == 'login') {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'pengajuan-pk' && (response.group == 'Direktur User' || response.group == 'Direktur HRS')) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'persetujuan-ganti-pejabat' && response.role != 'Admin' && response.role != 'Super Admin') {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'persetujuan-ubah-pk' && response.role != 'Admin' && response.role != 'Super Admin') {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'persetujuan-garansi' && !((response.role == 'Admin' && response.group == 'RnP 2') || response.role == 'Super Admin')) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'master-pejabat-approval' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-pejabat-approval' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'master-lead-time' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-lead-time' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'master-departemen' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-departemen' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'master-jabatan' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-jabatan' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'master-user' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-user' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'budget-pk' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else if (to.name == 'form-pk' &&  !(response.role == 'Super Admin' || (response.role == 'Admin' && response.group != 'Recruitment Cabang'))) {
	// 				next({name: "beranda"})
	// 			} else {
	// 				if (storedPKBerjalan && from.name == 'pk-berjalan' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPKBerjalan', null)
	// 				} else if (storedPKBerjalan && to.name != 'pk-berjalan' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPKBerjalan', null)
	// 				}
	// 				if (storedPKTerhutang && from.name == 'pk-terhutang' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPKTerhutang', null)
	// 				} else if (storedPKTerhutang && to.name != 'pk-terhutang' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPKTerhutang', null)
	// 				}
	// 				if (storedPersetujuanPK && from.name == 'persetujuan-pk' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPersetujuanPK', null)
	// 				} else if (storedPersetujuanPK && to.name != 'persetujuan-pk' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPersetujuanPK', null)
	// 				}
	// 				if (storedGantiPejabat && from.name == 'persetujuan-ganti-pejabat' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredGantiPejabat', null)
	// 				} else if (storedGantiPejabat && to.name != 'persetujuan-ganti-pejabat' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredGantiPejabat', null)
	// 				}
	// 				if (storedUbahPK && from.name == 'persetujuan-ubah-pk' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredUbahPK', null)
	// 				} else if (storedUbahPK && to.name != 'persetujuan-ubah-pk' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredUbahPK', null)
	// 				}
	// 				if (storedPersetujuanGaransi && from.name == 'persetujuan-garansi' && (to.name != 'detail-pk' && to.name != 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPersetujuanGaransi', null)
	// 				} else if (storedPersetujuanGaransi && to.name != 'persetujuan-garansi' && (from.name == 'detail-pk' || from.name == 'detail-rekrutmen')) {
	// 					store.dispatch('setStoredPersetujuanGaransi', null)
	// 				}
	// 				next()
	// 			}
	// 		})
	// 	} else {
	// 		store.dispatch('logout')
	// 		localStorage.routingTo = to.name
	// 		next({name: "login"})
	// 	}
	// }
});

export default router
